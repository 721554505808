<template>
  <div>
    <section v-if="isSliderShown" ref="container">
      <h2 :class="$style.title">{{ block.title }}</h2>

      <ScrollViewport
        ref="scrollerEl"
        orientation="horizontal"
        tag="div"
        :class="$style.list"
        :x="offsetLeftPx"
        role="list"
      >
        <template v-if="isPromoBlockOrMomentList">
          <NavigatableItem
            v-for="(moment, index) in block.contentMomentsList"
            :key="moment.id"
            :focus-key="getFocusKey(index)"
            :class="[$style.link, $style.item]"
            :active-class="$style.active"
            :tag="AppSlotButton"
            :on-click="() => openContentPage({ contentType: moment.contentType, id: moment.contentId })"
            :on-focus="() => onFocus(index)"
            @active="(element) => handleActiveItem(moment, element, index)"
          >
            <app-image :src="moment.preview" :class="$style.preview" :width="250" loading="lazy" />
          </NavigatableItem>
        </template>

        <template v-if="isDsmlRecommendations">
          <NavigatableItem
            v-for="(recommendation, index) in recommendations"
            :key="recommendation.id"
            :focus-key="getFocusKey(index)"
            :class="[$style.link, $style.item]"
            :active-class="$style.active"
            :tag="AppSlotButton"
            :on-click="() => openContentPage({ contentType: recommendation.contentType, id: recommendation.id })"
            :on-focus="() => onFocus(index)"
            @active="(element) => handleActiveItem(recommendation, element, index)"
          >
            <app-image :src="recommendation.background" :class="$style.preview" :width="250" loading="lazy" />
          </NavigatableItem>
        </template>

        <template v-if="isGenresBelt">
          <NavigatableItem
            v-for="(belt, index) in block.beltItems"
            :key="belt.id"
            :focus-key="getFocusKey(index)"
            :class="[$style.link, $style.item]"
            :tag="AppSlotButton"
            :style="{ backgroundColor: belt && belt.backgroundColor }"
            :on-click="() => openCatalogPage(belt)"
            :on-focus="() => onFocus(index)"
            @active="(element) => handleActiveItem(belt, element, index)"
          >
            <app-image
              v-if="belt.icon"
              :timeout-loading-ms="(index + 1) * 500"
              :src="belt.icon"
              :class="$style.icon"
              loading="lazy"
            />
          </NavigatableItem>
        </template>

        <template v-if="isContinueWatch">
          <NavigatableItem
            v-for="(watchingItem, index) in watchingItems"
            :key="watchingItem.id"
            :focus-key="getFocusKey(index)"
            :class="[$style.link, $style.item]"
            :active-class="$style.active"
            :tag="AppSlotButton"
            tabindex="0"
            :on-click="() => onContinueWatchClick()"
            :on-focus="() => onFocus(index)"
            @active="(element) => handleActiveItem(watchingItem, element, index)"
          >
            <app-image :src="watchingItem.preview" :class="$style.preview" :width="250" loading="lazy" />
          </NavigatableItem>
        </template>

        <template v-if="isChannels">
          <ChannelItem
            v-for="(channel, index) in channels"
            :key="channel.id"
            :class="$style.tvItem"
            :channel="channel"
            :focus-key="getFocusKey(index)"
            :on-focus="() => onFocus(index)"
            @active="(element) => handleActiveItem(channel, element, index)"
          />
        </template>
      </ScrollViewport>
    </section>
  </div>
</template>

<script>
import { DisplayType, MediaContentType } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import {
  catalogService,
  RouterPage,
  routerService,
  scrollToElement,
  storeToRefs,
  useMainPageStore,
  useMediaContentActions,
  useSessionStore,
} from '@SMART/index';
import { computed, onMounted, provide, ref } from '@vue/composition-api';

import AppButton from '@/components/app-button/AppButton.vue';
import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import UIModal from '@/components/modal/UIModal.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

import ChannelItem from './components/ChannelItem.vue';

export default {
  components: {
    ChannelItem,
    ScrollViewport,
    UIModal,
    AppImage,
  },
  props: {
    block: { default: () => ({}) },
    recommendations: { default: () => [] },
    watchingItems: { default: () => [] },
    channels: { default: () => [] },
    focusKeyPrefix: { type: String },
  },
  setup(props, { emit }) {
    const mainPageStore = useMainPageStore();
    const { openContentPage, openPlayerPage } = useMediaContentActions();
    const container = ref();
    const offsetLeftPx = ref(0);
    /**
     *
     * @type {Ref<Vue>}
     */
    const scrollerEl = ref();
    /**
     *
     * @type {ComputedRef<HTMLElement>}
     */
    const scrollerElement = computed(() => scrollerEl.value.$el);

    const { profile } = storeToRefs(useSessionStore());

    const isSliderShown = computed(() => {
      if (
        [
          DisplayType.PromoBlock,
          DisplayType.MomentList,
          DisplayType.GenresBelt,
          DisplayType.ContentMomentList,
        ].includes(props.block.displayType)
      ) {
        return true;
      }

      if (props.block.displayType === DisplayType.TvChannelsList && props.channels.length) {
        return true;
      }

      if (props.block.displayType === DisplayType.ContinueWatch && props.watchingItems.length) {
        return true;
      }

      if (props.block.displayType === DisplayType.DsmlRecommendations && props.recommendations.length) {
        return true;
      }

      return false;
    });
    const selectedContinueWatchingItem = ref();
    const isContinueWatch = computed(() => props.block.displayType === DisplayType.ContinueWatch);
    const isChannels = computed(() => props.block.displayType === DisplayType.TvChannelsList);

    const isPromoBlockOrMomentList = computed(() =>
      [DisplayType.PromoBlock, DisplayType.MomentList, DisplayType.ContentMomentList].includes(props.block.displayType),
    );

    const isDsmlRecommendations = computed(() => props.block.displayType === DisplayType.DsmlRecommendations);
    const isGenresBelt = computed(() => props.block.displayType === DisplayType.GenresBelt);

    const onFocus = (index, watchingItem) => {
      const focusKey = getFocusKey(index);
      mainPageStore.updateSelectedBeltItem({ focusKey, index, id: watchingItem?.id });
    };

    const getFocusKey = (index) => {
      return `${props.focusKeyPrefix}-${index + 1}`;
    };

    /**
     *
     * @param item
     * @param element {HTMLElement}
     * @param index
     */
    const handleActiveItem = (item, element, index) => {
      selectedContinueWatchingItem.value = item;

      scrollToElement(scrollerElement.value, { left: element.offsetLeft });

      emit('activated', item, index, element);

      const focusKey = getFocusKey(index);
      mainPageStore.updateSelectedBeltItem({ focusKey, index, id: item.id });
    };

    const onContinueWatchClick = async () => {
      if (!selectedContinueWatchingItem.value) {
        return;
      }

      const { contentType, title, serialId, id } = selectedContinueWatchingItem.value;

      return openPlayerPage({
        episodeId: contentType !== MediaContentType.Movie ? id : undefined,
        contentType: contentType === MediaContentType.Movie ? MediaContentType.Movie : MediaContentType.Serial,
        title,
        id: contentType !== MediaContentType.Movie ? serialId : id,
      });
    };

    const openCatalogPage = (belt) => {
      return routerService.push({
        name: RouterPage.CatalogPage,
        query: {
          genre: belt.genresSlugs,
        },
      });
    };

    const onRemoveContinueWatchItem = async () => {
      if (!selectedContinueWatchingItem.value) {
        throw new UnexpectedComponentStateError('selectedContinueWatchingItem');
      }

      await catalogService.hideWatchItem(profile.value.id, selectedContinueWatchingItem.value.id);

      emit('update:watching-items', selectedContinueWatchingItem.value.id);
      onCloseModal(false);
    };

    return {
      container,
      scrollerEl,
      offsetLeftPx,
      isSliderShown,
      selectedContinueWatchingItem,
      isChannels,
      AppButton,
      isPromoBlockOrMomentList,
      isDsmlRecommendations,
      isGenresBelt,
      onFocus,
      handleActiveItem,
      onContinueWatchClick,
      onRemoveContinueWatchItem,
      AppSlotButton,
      openCatalogPage,
      openContentPage,
      openPlayerPage,
      isContinueWatch,
      getFocusKey,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/colors';
@import '@/styles/layers';

.title {
  margin-top: adjustPx(24px);
  margin-bottom: adjustPx(24px);

  @include f-subtitle-2;
}

.list {
  display: flex;
  padding-right: 120px;
}

.item {
  position: relative;
  max-width: adjustPx(371px);
  height: adjustPx(225px);
  border-radius: adjustPx(36px);
  overflow: hidden;
  min-width: adjustPx(371px);
  margin-right: adjustPx(16px);
  outline: none;

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }
}

.tvItem {
  position: relative;
  max-width: adjustPx(371px);
  height: adjustPx(302px);
  border-radius: adjustPx(36px);
  overflow: hidden;
  min-width: adjustPx(371px);
  margin-right: adjustPx(16px);
  outline: none;

  img {
    height: 100%;
    object-fit: cover;
  }

  &:last-child {
    margin-right: 0;
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: adjustPx(36px);
    content: '';
  }
}

.preview {
  opacity: 1;
  overflow: hidden;
  transform: scale(1.35, 1.35);
}

.icon {
  opacity: 1;
  overflow: hidden;
  transform: scale(0.5, 0.5);
  min-height: fit-content;
}

img.icon {
  position: absolute;
  top: -25%;
  left: 0;
  min-width: adjustPx(350px);
  height: fit-content;
  min-height: adjustPx(350px);
}

.continueWatchModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: map-get($map: $layers, $key: --z-index-alert);
  display: flex;
  align-items: center;
  width: 100%;
  background: var(--color-bg-secondary-60);
  flex-flow: column;
  justify-content: center;

  &Button {
    min-width: adjustPx(504px);
    margin-top: adjustPx(24px);

    &:first-child {
      margin-top: 0;
    }
  }

  &Active {
    border-color: var(--color-bg-accent);
    background-color: var(--color-bg-accent);
  }
}
</style>
