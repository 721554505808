<template>
  <div ref="el" :class="$style.wrapper">
    <div :class="$style.channels">
      <ScrollViewport ref="scrollEl" tag="div" :class="$style.list" :y="offsetTopPx" role="list">
        <NavigatableItem
          v-for="(channel, index) in channels"
          :key="channel.id"
          :tag="AppSlotButton"
          :autofocus="selectedItem && selectedItem.value.id === channel.id"
          :class="$style.item"
          :on-click="() => openTvChannelPage(channel)"
          @active="(element) => handleActiveItem(channel, element, index)"
        >
          <app-image :key="channel.logo" :class="$style.logo" :src="channel.logo" :width="420" />
        </NavigatableItem>
      </ScrollViewport>
    </div>

    <div v-if="activeChannel && activeChannel.currentProgram" :class="$style.detailsWrapper">
      <div :class="$style.details">
        <p :class="$style.detailsTitle">{{ activeChannel.currentProgram.title }}</p>

        <div v-if="activeChannel.currentProgram.startTime" :class="$style.detailsTime">
          <p :class="$style.detailsTimeText">
            {{ getTvProgramStartTime(activeChannel.currentProgram.startTime) }}
            —
            {{ getTvProgramEndTime(activeChannel.currentProgram.startTime, activeChannel.currentProgram.duration) }}
          </p>

          <p v-if="activeChannel" :class="$style.detailsTimeStatus">
            {{ getTvChannelStickerText(activeChannel.accessKind) }}
          </p>
        </div>

        <div v-if="isShowAboutMovie && activeChannel.currentProgram" :class="$style.detailsControls">
          <NavigatableItem
            :tag="AppButton"
            :text="$t('pages.channels.aboutMovie')"
            :on-click="
              () =>
                openContentPage({
                  contentType: activeChannel.currentProgram.contentType,
                  id: activeChannel.currentProgram.contentId,
                })
            "
          />
        </div>

        <div
          v-if="activeChannel && activeChannel.availability && activeChannel.nextProgram"
          :class="$style.detailsNextMovie"
        >
          <p :class="$style.detailsNextMovieText">{{ $t('pages.channels.nextMovieText') }}</p>

          <div :class="$style.detailsNextMovieInfo">
            <span :class="$style.detailsNextMovieInfoTime">
              {{ getTvProgramStartTime((activeChannel.nextProgram && activeChannel.nextProgram.startTime) || '') }}
            </span>
            <span :class="$style.detailsNextMovieInfoTitle">
              {{ activeChannel.nextProgram && activeChannel.nextProgram.title }}
            </span>
          </div>
        </div>

        <div v-if="!activeChannel.availability" :class="$style.detailsNotAvailable">
          <div :class="$style.detailsNotAvailableLogo">
            <TvIcon />
          </div>

          <div :class="$style.detailsNotAvailableContent">
            <h2 :class="$style.detailsNotAvailableTitle">{{ $t('pages.channels.notAvailableTitle') }}</h2>

            <p :class="$style.detailsNotAvailableText">{{ $t('pages.channels.notAvailableText') }}</p>
          </div>
        </div>

        <div :class="$style.detailsLogo">
          <app-image :key="activeChannel.logo" :src="activeChannel.logo" :width="420" :class="$style.logo" />
        </div>
      </div>

      <div :class="$style.placeholder" />
    </div>
  </div>
</template>

<script>
import { DisposableStore, indexOutOfRange, TvKeyCode, UnexpectedComponentStateError } from '@package/sdk/src/core';
import useNavigatable from '@package/smarttv-navigation/src/use-navigatable';
import TvIcon from '@SMART/assets/icons/51x51/tv.svg';
import {
  channelsService,
  keyboardEventHandler,
  routerService,
  storeToRefs,
  useCatalogStore,
  useMediaContentActions,
  useTvChannelActions,
  useTvChannelsStore,
} from '@SMART/index';
import { computed, nextTick, onBeforeUnmount, onMounted, provide, ref } from '@vue/composition-api';
import { addSeconds, format, isValid, parseISO } from 'date-fns';

import AppButton from '@/components/app-button/AppButton.vue';
import AppImage from '@/components/app-image/AppImage.vue';
import AppSlotButton from '@/components/app-slot-button/AppSlotButton.vue';
import UIButton from '@/components/button/UIButton.vue';
import ScrollViewport from '@/components/scroll-viewport/ScrollViewport.vue';

export default {
  components: {
    TvIcon,
    AppImage,
    ScrollViewport,
    UIButton,
  },
  setup(props, { parent: { $route: route } }) {
    const catalogStore = useCatalogStore();
    const tvChannelsStore = useTvChannelsStore();

    const { selectedItem } = storeToRefs(catalogStore);
    const { channels } = storeToRefs(tvChannelsStore);

    const { getTvChannelStickerText, openTvChannelPage } = useTvChannelActions();
    const { openContentPage } = useMediaContentActions();

    /**
     *
     * @type {Ref<Vue>}
     */
    const scrollEl = ref();

    const { el, focusSelf, focusKey } = useNavigatable({
      focusKey: 'CHANNELS_PAGE',
      trackChildren: true,
    });
    provide('parentFocusKey', focusKey.value);

    /**
     *
     * @type {Ref<HTMLElement[]>}
     */
    const list = ref();
    /**
     *
     * @type {Ref<UnwrapRef<number>>}
     */
    const offsetTopPx = ref(0);
    /**
     *
     * @type {Ref<Channel>}
     */
    const activeChannel = ref();

    const disposableStore = new DisposableStore();

    /**
     *
     * @param channel {Channel}
     * @param element {HTMLElement}
     * @param index {number}
     */
    const handleActiveItem = (channel, element, index) => {
      if (!element) {
        throw new UnexpectedComponentStateError('element');
      }

      catalogStore.updateSelectedItem({ value: channel, rowId: index });

      offsetTopPx.value = element.offsetTop || 0;
      activeChannel.value = channel;
    };

    /**
     * @param startTime {string}
     * @return {string|string}
     */
    const getTvProgramStartTime = (startTime) => {
      const time = parseISO(startTime);

      return isValid(time) ? format(time, 'HH:mm') : '';
    };

    /**
     * @param startTime {string}
     * @param duration {number}
     * @return {string}
     */
    const getTvProgramEndTime = (startTime, duration = 0) => {
      const time = parseISO(startTime);

      if (isValid(time)) {
        return format(addSeconds(time, duration), 'HH:mm');
      }

      return '';
    };

    /**
     *
     * @type {ComputedRef<string>}
     */
    const backgroundSrc = computed(
      () => activeChannel.value?.currentProgram?.background ?? activeChannel.value?.background,
    );

    /**
     * @type {ComputedRef<boolean>}
     */
    const isShowAboutMovie = computed(
      () =>
        activeChannel.value?.availability &&
        activeChannel.value?.currentProgram?.contentId &&
        activeChannel.value?.currentProgram?.contentType,
    );

    /**
     * @param index {number}
     * @return {Promise<void>}
     */
    const selectChannelRowByIndex = async (index, event) => {
      let idx = index;

      if (idx <= 0) {
        idx = 0;
      }

      if (idx >= channels.value.length) {
        idx = channels.value.length - 1;
      }

      /**
       * @type {HTMLElement}
       */
      const element = list.value[idx].children[0];
    };

    const onPrevChannel = (event) => selectChannelRowByIndex((selectedItem.value?.rowId || 0) - 1, event);

    const onNextChannel = (event) => selectChannelRowByIndex((selectedItem.value?.rowId || 0) + 1, event);

    const selectChannelById = async () => {
      const query = Object.assign({}, route.query);

      const index = channels.value.findIndex((channel) => channel.id === query.channelId);

      if (!indexOutOfRange(index)) {
        delete query.channelId;

        await routerService.replace({ query });
      }
    };

    onMounted(async () => {
      await channelsService.fetchChannels();

      disposableStore.add(keyboardEventHandler.on(TvKeyCode.CHANNEL_DOWN, onNextChannel));
      disposableStore.add(keyboardEventHandler.on(TvKeyCode.CHANNEL_UP, onPrevChannel));

      await nextTick();

      focusSelf();

      if (route.query.channelId) {
        return selectChannelById();
      }
    });

    onBeforeUnmount(() => {
      disposableStore.dispose();
    });

    return {
      el,
      scrollEl,
      selectedItem,
      AppSlotButton,
      AppButton,
      backgroundSrc,
      activeChannel,
      getTvProgramStartTime,
      getTvChannelStickerText,
      getTvProgramEndTime,
      handleActiveItem,
      isShowAboutMovie,
      channels,
      offsetTopPx,
      list,
      openTvChannelPage,
      openContentPage,
    };
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/fonts';
@import '@/styles/colors';
@import '@/styles/layers';

.wrapper {
  display: flex;
  flex-direction: row;
  margin-left: adjustPx(140px);
  padding-top: adjustPx(60px);
}

.channels {
  z-index: map-get($map: $layers, $key: --z-index-heading);
  overflow: hidden;
}

.list {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-heading);

  :nth-child(n) {
    margin-top: adjustPx(17px);
  }
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  width: adjustPx(368px);
  height: adjustPx(179px);
  border-radius: adjustPx(20px);
  border: none;
  background-color: var(--color-bg-secondary);
  justify-content: center;
  outline: none;
}

.logo {
  width: 80%;
}

.preview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
}

.background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: map-get($map: $layers, $key: --z-index-pseudo-elements);
}

.details {
  position: relative;
  z-index: map-get($map: $layers, $key: --z-index-heading);
  display: flex;
  margin: 0 0 0 adjustPx(60px);
  height: 80%;
  flex-direction: column;

  &Wrapper {
    position: fixed;
    top: adjustPx(80px);
    bottom: 0;
    left: adjustPx(586px);
    right: 0;
    flex: 1 1;
  }

  &Controls {
    margin-top: adjustPx(60px);

    a {
      display: inline-block;
      border-radius: adjustPx(45px);
      outline: none;
    }

    a:hover {
      background-color: var(--color-bg-accent);
    }
  }

  &Title {
    max-width: adjustPx(990px);

    @include f-title-1;
  }

  &Logo {
    position: absolute;
    top: adjustPx(20px);
    right: adjustPx(120px);
    width: adjustPx(300px);

    img {
      width: 100%;
    }
  }

  &Time {
    display: flex;
    margin-top: adjustPx(36px);

    &Text {
      margin-right: adjustPx(24px);
      color: #888;
    }

    &Status {
      color: var(--color-text-accent);
    }
  }

  &NextMovie {
    position: fixed;
    bottom: adjustPx(140px);

    &Text {
      margin-bottom: adjustPx(33px);
      color: var(--color-notheme-white-50);
      text-transform: uppercase;

      @include f-caption-2;
    }

    &Info {
      display: flex;
      align-items: center;

      @include f-body;

      &Time {
        @include f-upper-headline;
      }

      &Title {
        max-width: adjustPx(740px);
        margin-left: adjustPx(104px);

        @include f-subtitle;
      }
    }
  }

  &NotAvailable {
    position: fixed;
    bottom: adjustPx(140px);
    display: flex;

    &Logo {
      position: relative;

      svg {
        width: adjustPx(64px);
        height: adjustPx(64px);
      }

      &::before {
        content: '';
        position: absolute;
        margin: adjustPx(16px) adjustPx(9px);
        width: adjustPx(46px);
        height: adjustPx(32px);
        background: var(--color-bg-accent);
      }
    }

    &Content {
      margin-left: adjustPx(48px);
    }

    &Title {
      margin-top: adjustPx(12px);

      @include f-headline-2;
    }

    &Text {
      margin-top: adjustPx(24px);

      @include f-body;
    }
  }
}
</style>
