<template>
  <div class="page-container">
    <Transition>
      <MyChannelSkeleton v-if="!currentKinom" should-show-poster />
    </Transition>
    <VideoPlayer ref="player" :is-controls-hidden="true" :class="$style.player" @mounted="onMountPlayer" />

    <section :class="$style.wrapper">
      <MyChannelReactions
        v-show="isControlShown"
        :moment="currentKinom"
        @like="onLikeMoment"
        @save="tryToSaveCollection"
        @active="onActivateButton"
      />
      <MyChannelControl
        v-show="isControlShown"
        :is-next-button-disabled="isNextButtonDisabled"
        :is-back-button-disabled="isBackButtonDisabled"
        :is-playing="isPlaying"
        @next="updateContent"
        @play="onPlay(!isPlaying)"
        @active="onActivateButton"
      />

      <Transition>
        <MyChannelPoster
          :class="$style.poster"
          :moment="currentKinom"
          :is-control-shown="isControlShown"
          @active="onActivateButton"
        />
      </Transition>

      <MyChannelNotifications
        is-onboarding-shown
        is-onboarding-like-shown
        is-onboarding-dislike-shown
        is-onboarding-save-shown
        :is-auth-error="isAuthError"
        @cancel="onCloseAuthError"
        @login="onLogin"
      />
    </section>
  </div>
</template>

<script>
import ConstantsConfigInstanceSmartTV from '@package/constants/code/constants-config-smart-tv';
import { LikeState } from '@package/sdk/src/api';
import { throttleWithImmediate } from '@package/sdk/src/core';
import BookmarkFillIcon from '@SMART/assets/icons/51x51/save.svg';
import {
  catalogService,
  collectionService,
  momentService,
  routerService,
  storeToRefs,
  useAuthActions,
  useCatalogStore,
  useContentStore,
  useSessionStore,
} from '@SMART/index';
import { computed, onActivated, onBeforeUnmount, onMounted, ref } from '@vue/composition-api';

import VideoPlayer from '@/components/video-player/VideoPlayer.vue';

import MyChannelControl from './components/MyChannelControl.vue';
import MyChannelNotifications from './components/MyChannelNotifications.vue';
import MyChannelPoster from './components/MyChannelPoster.vue';
import MyChannelReactions from './components/MyChannelReactions.vue';
import MyChannelSkeleton from './components/MyChannelSkeleton.vue';
import { useNotifications } from './components/useNotifications';

export default {
  components: {
    BookmarkFillIcon,
    VideoPlayer,
    MyChannelControl,
    MyChannelNotifications,
    MyChannelPoster,
    MyChannelReactions,
    MyChannelSkeleton,
  },
  props: {
    page: {
      type: String | Number,
      default: '',
    },
    size: {
      type: String | Number,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'all',
    },
  },
  setup(props, { refs }) {
    const MomentsType = {
      Saved: 'saved',
      All: 'all',
      Content: 'content',
    };

    const contentStore = useContentStore();
    const catalogStore = useCatalogStore();
    const userStore = useSessionStore();

    const { user } = storeToRefs(userStore);

    const { content } = storeToRefs(contentStore);

    const { isAuthError } = useNotifications();

    const { openAuthPage } = useAuthActions();

    const momentsFilter = props.page ? { ...props } : null;

    const isControlShown = ref(false);
    const isPlaying = ref(false);
    const isLastPage = ref(false);

    let player;

    const moments = ref([]);
    const momentsType = ref(props.type);

    const playlists = ref([]);

    let shouldFindMoment = Boolean(props.id);
    let timeoutIndex = 0;

    const currentMomentIndex = ref(0);

    const currentPage = ref(1);
    const isBackButtonDisabled = computed(() => currentMomentIndex.value <= 0 && currentPage.value === 1);
    const isContent = computed(() => [MomentsType.Saved, MomentsType.Content].includes(momentsType.value));
    const isNextButtonDisabled = computed(
      () => currentMomentIndex.value >= moments.value.length - 1 && isContent.value,
    );

    const hideControl = () => {
      if (timeoutIndex) {
        window.clearTimeout(timeoutIndex);
      }

      timeoutIndex = window.setTimeout(async () => {
        isControlShown.value = false;
      }, ConstantsConfigInstanceSmartTV.getProperty('hideControlTimeoutMs'));
    };

    const onActivateButton = throttleWithImmediate(
      async () => {
        isControlShown.value = true;

        hideControl();
      },
      { timeout: 20, immediate: true },
    );

    const onMouseListener = throttleWithImmediate(
      async () => {
        isControlShown.value = true;
        hideControl();
      },
      { timeout: 20, immediate: true },
    );

    const loadSavedMoments = async (page) => {
      if (!momentsFilter) {
        return [];
      }

      let data = [];

      try {
        data = await collectionService.fetchCollectionMoments({
          page: shouldFindMoment ? Number(momentsFilter.page) : page,
          size: Number(momentsFilter.size),
        });
      } catch {
        data = await Promise.all(collectionService.savedMomentsItems.map((id) => momentService.fetchMoment(id)));
      }

      if (shouldFindMoment) {
        shouldFindMoment = false;
        currentMomentIndex.value = data.findIndex((moment) => moment.id === momentsFilter.id);
        currentPage.value = Number(momentsFilter.page);

        if (currentMomentIndex.value === -1) {
          routerService.back();
        }
      }

      return data;
    };
    const fetchData = async (page) => {
      try {
        if (momentsType.value === MomentsType.Content) {
          return await catalogService.fetchMoments(props.id);
        }

        if (momentsType.value === MomentsType.Saved) {
          return await loadSavedMoments(page);
        }

        return await momentService.fetchMoments({
          page,
          perPage: ConstantsConfigInstanceSmartTV.getProperty('contentPageSize'),
        });
      } catch {
        return [];
      } finally {
        hideControl();
      }
    };

    const onCloseAuthError = () => {
      isAuthError.value = false;
    };

    const onLogin = () => openAuthPage();

    const updateContent = async (step = 1, isAuto = false) => {
      onCloseAuthError();
      const page = currentPage.value;
      const index = currentMomentIndex.value;

      if (timeoutIndex && !isControlShown.value) {
        window.clearTimeout(timeoutIndex);
      }

      currentMomentIndex.value += step;

      if (currentMomentIndex.value === -1 && currentPage.value === 1) {
        return;
      }

      if (
        currentMomentIndex.value >= moments.value.length &&
        [MomentsType.Saved, MomentsType.Content].includes(momentsType.value)
      ) {
        currentPage.value = 1;
        moments.value = await fetchData(currentPage.value);

        if (!moments.value.length) {
          return;
        }
      }

      if (currentMomentIndex.value === moments.value.length) {
        currentPage.value += 1;
        const data = await fetchData(currentPage.value);

        moments.value = [...moments.value, ...data];
      }

      let moment = moments.value[currentMomentIndex.value];

      if (!moment) {
        currentMomentIndex.value = index;
        currentPage.value = page;
        moment = moments.value[currentMomentIndex.value];
      }

      if (!moment) {
        isLastPage.value = true;
        return;
      }

      player.setConfigProperty('content.media', moment);
      player.load({ src: moment.hls, id: moment?.id });

      contentStore.$patch({ _content: undefined });

      await contentStore.fetchContent({
        id: moment.contentId,
        type: moment.contentType,
      });

      player.play({ manual: false });

      isPlaying.value = true;
    };

    const currentKinom = computed(() => moments.value[currentMomentIndex.value]);

    const onPlayerMounted = () => {
      updateContent(0);
    };

    const onPlayerEnded = () => {
      if (isNextButtonDisabled.value) {
        updateContent(0);
      } else {
        updateContent(+1, true);
      }
    };

    const onPlayerError = () => {
      updateContent(+1);
    };

    const onMountPlayer = () => {
      player = refs.player;
      onPlayerMounted();
      player.on('ended', onPlayerEnded);
      player.on('error', onPlayerError);
      onPlay(true);
    };

    onMounted(async () => {
      try {
        const [data] = await Promise.all([fetchData(1), collectionService.updateSavedItems()]);

        moments.value = data;

        window.addEventListener('mousedown', onMouseListener);
        window.addEventListener('mousemove', onMouseListener);
        window.addEventListener('mouseup', onMouseListener);
        window.addEventListener('keypress', onActivateButton);
        window.addEventListener('keyup', onActivateButton);
        window.addEventListener('keydown', onActivateButton);

        hideControl();

        if (user.value) {
          momentService.saveItems();
        }
      } catch (e) {
        console.error(e);
      }
    });

    onActivated(async () => {
      if (player && currentKinom.value) {
        player.play({ manual: false });
      }

      if (currentKinom.value && !content.value) {
        contentStore.fetchContent({
          id: currentKinom.value.contentId,
          type: currentKinom.value.contentType,
        });
      }
    });

    const onPlay = (state) => {
      isPlaying.value = state;

      hideControl();

      return isPlaying.value ? player.play({ manual: true }) : player.pause({ manual: true });
    };

    const onLikeMoment = async (reaction) => {
      const id = currentKinom.value?.id;

      hideControl();

      const currentLikeState = currentKinom.value.likeState;
      const state = currentLikeState === reaction ? LikeState.Cancel : reaction;

      try {
        await momentService.likeMoment([id], state);

        moments.value = moments.value.map((item) => ({
          ...item,
          likeState: item.id === id ? state : item.likeState,
        }));
      } catch (e) {
        if (!user.value) {
          isAuthError.value = true;
        }
      } finally {
        if (state === LikeState.Dislike && !isAuthError.value) {
          if (!isAuthError.value) {
            window.setTimeout(() => {
              updateContent(1);
            }, 600);
          }
        }
      }
    };

    const onRemoveCollectionItem = async (id) => {
      await collectionService.removeItems([id], 'moment');

      moments.value = moments.value.map((item) => ({
        ...item,
        inUserCollection:
          item.id === id ? false : item.inUserCollection || collectionService.savedMomentsItems.includes(item.id),
      }));
    };

    const onSaveCollectionItem = async (id) => {
      try {
        await collectionService.saveItems([id], 'moment');

        moments.value = moments.value.map((item) => ({
          ...item,
          inUserCollection:
            item.id === id ? true : item.inUserCollection || collectionService.savedMomentsItems.includes(item.id),
        }));
      } catch (e) {
        isAuthError.value = true;
      }
    };

    onBeforeUnmount(() => {
      window.removeEventListener('mousedown', onMouseListener);
      window.removeEventListener('mousemove', onMouseListener);
      window.removeEventListener('mouseup', onMouseListener);
      window.removeEventListener('keypress', onActivateButton);
      window.removeEventListener('keyup', onActivateButton);
      window.removeEventListener('keydown', onActivateButton);
    });

    const isCurrentVideoSaved = computed(
      () => currentKinom.value?.inUserCollection || collectionService.savedMomentsItems?.includes(props.moment?.id),
    );

    const tryToSaveCollection = async () => {
      const id = currentKinom.value.id;

      hideControl();

      if (!currentKinom.value) {
        return;
      }

      // update in order to reload user collection
      catalogStore.setUpdated(true);

      if (isCurrentVideoSaved.value) {
        return onRemoveCollectionItem(id);
      }

      await onSaveCollectionItem(id);
    };
    return {
      currentKinom,
      playlists,
      isControlShown,
      onLikeMoment,
      tryToSaveCollection,
      onActivateButton,
      isNextButtonDisabled,
      isBackButtonDisabled,
      isPlaying,
      updateContent,
      onPlay,
      isAuthError,
      onCloseAuthError,
      onLogin,
      onMountPlayer,
    };
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

<style module lang="scss">
@import '@/styles/fonts';
@import '@/styles/mixins';
@import '@/styles/colors';
@import '@/styles/layers';

.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.title {
  position: fixed;
  top: adjustPx(48px);
  right: adjustPx(48px);
  z-index: map-get($map: $layers, $key: --z-index-modal);
  display: flex;
  align-items: center;
}

.poster {
  margin-right: auto;
}

.player {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  video {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.45, 1.45);
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: map-get($map: $layers, $key: --z-index-modal);
  background: var(--color-notheme-dim-black-60);
}
</style>
