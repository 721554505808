import { render, staticRenderFns } from "./AuthPage.vue?vue&type=template&id=bbe64904"
import script from "./AuthPage.vue?vue&type=script&lang=js"
export * from "./AuthPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.8_babel-core@7.0.0-bridge.0_@babel+core@7.25.2__css-_mxhs3nrbvkrq7rxnh5spv3beea/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports